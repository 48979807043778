body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.satisfied-customers > :first-child {
  margin-top: 60px;
}

.satisfied {
  background-image: linear-gradient(176deg, #fcb316 50%, #0093d3 calc(50% + 2px));
}

.footer {
  justify-content: space-between;
}

@media screen and (max-width: 560px) {
  .footer {
    justify-content: center;
    align-items:center;
    text-align: center;
  }

  .satisfied {
    flex-direction: row;
    background-image: linear-gradient(176deg, #fcb316 43%, #0093d3 calc(43% + 2px));
  }

  .satisfied-customers {
    order: 0;
    flex-direction: row;
  }
  .satisfied-customers > :first-child {
    order: 1;
    margin-top:16px;
  }
}